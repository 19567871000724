<template>
  <!-- <h2>账户信息</h2> -->
  <div>
    <div class="account-info-page">
      <div class="account-info-content">
        <div>
          <el-upload
            class="avatar-uploader"
            action="#"
            ref="uploadAvatar"
            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
            :multiple="false"
            :show-file-list="false"
            :limit="1"
            :http-request="uploadAvatar"
          >
            <el-button type="text" class="change-avatar">修改头像</el-button>
            <img v-if="accountInfoForm.avatar != ''" :src="accountInfoForm.avatar" class="avatar" />
            <div v-else style="text-align: center">
              <span class="name">
                {{ name }}
              </span>
            </div>
          </el-upload>
        </div>
        <div class="body">
          <div class="flex">
            <span>姓名：</span>
            <span>{{ accountInfoForm.nickname }}</span>
          </div>
          <div class="flex">
            <span>部门：</span>
            <span>{{ accountInfoForm.auth }}</span>
          </div>
          <div class="flex">
            <span>创建时间：</span>
            <span>{{ accountInfoForm.createtime }}</span>
          </div>
          <div class="flex">
            <span>邮箱：</span>
            <span>
              {{ accountInfoForm.email }}
              <em @click="edit('email')">更换</em>
            </span>
          </div>
          <div class="flex">
            <span>绑定手机号：</span>
            <span>
              {{ accountInfoForm.mobile }}
              <em @click="edit('phone')">更换</em>
            </span>
          </div>
          <div class="flex">
            <span>密码：</span>
            <span>
              ******
              <em @click="edit('password')">重置</em>
            </span>
          </div>
          <div style="text-align: center">
            <el-button
              size="small "
              plain
              style="background-color: white; padding: 10px 80px"
              @click="edit('message')"
            >
              编辑
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改信息弹窗 -->
    <el-dialog :title="alter" :visible.sync="dialogVisible" width="20%" center>
      <el-form
        ref="message"
        :model="message"
        :rules="rules"
        label-width="80px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="nickname" v-if="alter == '修改信息'">
          <el-input
            class="w-260"
            size="small"
            v-model="message.nickname"
            placeholder="请输入要修改的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email" v-if="alter == '修改邮箱'">
          <el-input
            class="w-260"
            size="small"
            v-model="message.email"
            placeholder="请输入要修改的邮箱"
          ></el-input>
        </el-form-item>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px 0 20px;
          "
          v-if="alter == '修改手机号'"
        >
          <span style="margin-left: 80px">{{ accountInfoForm.mobile }}</span>
          <el-button
            type="info"
            size="mini"
            plain
            :disabled="isTrue"
            style="width: 100px"
            @click="securityCode"
          >
            {{ isTime }}
          </el-button>
        </div>
        <el-form-item label="验证码：" prop="security" v-if="alter == '修改手机号'">
          <el-input
            class="w-260"
            size="small"
            v-model="message.security"
            placeholder="请输入验证码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile" v-if="alter == '修改手机号'">
          <el-input
            class="w-260"
            size="small"
            v-model="message.mobile"
            placeholder="请输入要修改的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="旧密码:"
          :prop="accountInfoForm.is_set_password == true ? 'passwordOld' : ''"
          v-if="alter == '修改密码'"
        >
          <el-input
            class="w-260"
            size="small"
            v-model="message.passwordOld"
            placeholder="请输入旧密码(没有密码时可不填)"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="passwordOld" v-if="alter == '修改密码'">
          <el-input
            class="w-260"
            size="small"
            v-model="message.passwordNew"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <div class="modify-save-btn">
          <!-- <el-button type="primary" @click="dialogVisible = false">取消</el-button> -->
          <el-button
            style="background-color: #29303e; border: 1px solid #29303e"
            type="primary"
            @click="handleSaveEmailBtn"
          >
            保存
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  userGet_user_info,
  accountmanageUploadavatar,
  userUpdate_email,
  userUpdate_user_name,
  updateoldmobilesendsms,
  update_password,
  newupdatemobile,
} from '@/common/accountManagement.js';
export default {
  components: {},
  data() {
    return {
      accountInfoForm: {
        // 账号ID
        id: '',
        // 账号
        username: '',
        // 昵称
        nickname: '',
        // 邮箱
        email: '',
        // 电话
        mobile: '',
        // 头像
        avatar: '',
        // 添加时间
        createtime: '',
        // 部门
        auth: '',
        // 密码
        is_set_password: true,
      },
      // 编辑文本框数据
      message: {
        nickname: '',
        email: '',
        mobile: '',
        passwordOld: '',
        passwordNew: '',
        // 验证码
        security: '',
      },
      rules: {
        nickname: [{ required: true, message: '姓名不能为空' }],
        email: [
          {
            required: true,
            pattern: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: '邮箱格式不正确，不含中文，开头必须是英文或者数字',
          },
        ],
        security: [{ required: true, message: '验证码不能为空' }],
        mobile: [{ required: true, message: '手机号不能为空' }],
        passwordOld: [{ required: true, message: '旧密码不能为空' }],
        passwordNew: [{ required: true, message: '新密码不能为空' }],
      },
      // 是否显示邮箱弹窗
      dialogVisible: false,
      // 是否显示修改弹窗
      dialogFormModifyEmailVisible: false,
      // 权限操作集合
      permissionsAction: {},
      // 修改提示框的名字
      alter: '',
      // 截取的名字
      name: '',
      // 倒计时按钮
      isTime: '获取验证码',
      // 倒计时按钮禁用
      isTrue: false,
    };
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.message.nickname = '';
        this.message.email = '';
        this.message.mobile = '';
        this.message.passwordOld = '';
        this.message.passwordNew = '';
        this.message.security = '';
      }
    },
  },
  mounted() {
    this.queryAccountInfoData();
  },
  methods: {
    // 返回倒计时
    secS() {
      let that = this;
      this.time = setInterval(() => {
        that.Countdown();
      }, 1000);
    },
    // 倒计时判断
    Countdown() {
      if (this.isTime > 0) {
        this.isTime = this.isTime < 11 ? `0${this.isTime - 1}` : this.isTime - 1;
      } else {
        this.isTrue = false;
        this.isTime = '获取验证码';
        clearInterval(this.time);
      }
    },
    // 获取当前手机号验证码
    securityCode() {
      updateoldmobilesendsms().then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.isTrue = true;
          this.secS();
          this.isTime = 120;
          this.$message.success(msg);
        }
      });
    },
    // 隐藏手机号
    spileNumber(num) {
      if (num != '' && num != null) {
        const reg = /^(.{3}).*(.{4})$/;
        this.accountInfoForm.mobile = num.replace(reg, '$1****$2');
      }
    },
    // 显示修改界面
    edit(e) {
      this.dialogVisible = true;
      if (e == 'password') {
        this.alter = '修改密码';
      }
      if (e == 'phone') {
        this.alter = '修改手机号';
      }
      if (e == 'email') {
        this.alter = '修改邮箱';
      }
      if (e == 'message') {
        this.alter = '修改信息';
      }
    },
    // 查询账户信息
    queryAccountInfoData() {
      userGet_user_info().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.accountInfoForm = result;
          this.captureName();
          // 隐藏手机号
          this.spileNumber(this.accountInfoForm.mobile);
        }
      });
    },
    // 处理修改头像
    uploadAvatar(file) {
      let formData = new FormData();
      formData.append('avatar_file', file.file);
      accountmanageUploadavatar(formData).then((res) => {
        let { code, msg, result } = res;
        if (code === 200) {
          this.$message({ type: 'success', message: `${msg}`, center: true });
          this.accountInfoForm.avatar = result;
          this.queryAccountInfoData();
          this.$refs.uploadAvatar.clearFiles();
        }
      });
    },
    // 获取名字最后两个字
    captureName() {
      if (this.accountInfoForm.nickname.length > 1) {
        this.name = this.accountInfoForm.nickname.substr(
          this.accountInfoForm.nickname.length - 2,
          2,
        );
      } else {
        this.name = this.accountInfoForm.nickname;
      }
    },
    // 修改个人信息
    handleSaveEmailBtn() {
      this.$refs['message'].validate((valid) => {
        if (valid) {
          if (this.alter == '修改信息') {
            let dt = {
              username: this.message.nickname,
            };
            userUpdate_user_name(dt).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.queryAccountInfoData();
              } else {
                this.$message.warning(res.msg);
              }
            });
          } else if (this.alter == '修改邮箱') {
            let dt = {
              email: this.message.email,
            };
            userUpdate_email(dt).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.queryAccountInfoData();
              } else {
                this.$message.warning(res.msg);
              }
            });
          } else if (this.alter == '修改密码') {
            let dt = {
              old_password: this.message.passwordOld,
              new_password: this.message.passwordNew,
            };
            update_password(dt).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.queryAccountInfoData();
              } else {
                this.$message.warning(res.msg);
              }
            });
          } else if (this.alter == '修改手机号') {
            let dt = {
              new_mobile: this.message.mobile,
              sms_code: this.message.security,
            };
            newupdatemobile(dt).then((res) => {
              if (res.code == 200) {
                clearInterval(this.time);
                this.isTrue = false;
                this.isTime = '获取验证码';
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.queryAccountInfoData();
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        } else {
          // this.$message.warning('');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  font-size: 14px;
  color: #848484;
  border-top: 1px solid rgb(221, 221, 221);
  margin-top: 20px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

em {
  font-style: normal;
  color: #0099ff;
  margin-left: 10px;
}

.name {
  display: inline-block;
  border: 1px solid #bbb;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  font-size: 30px;
}

.avatar-uploader {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.change-avatar {
  position: absolute;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.avatar-uploader:hover .change-avatar {
  opacity: 1;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

.account-info-page {
  padding: 20px 20px 50px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  box-shadow: 0px 5px 10px 1px #bebebe77;
}

.account-info-content {
  width: 460px;
  padding: 20px;
}

.modify-save-btn {
  text-align: center;
}
</style>
