import $http from '@/utils/http';

// 获取个人信息
export function userGet_user_info(data) {
  return $http.get('/api/user/get_user_info', data, 'loadingDiv');
}

// 修改上传头像
export function accountmanageUploadavatar(data) {
  return $http.post('/v2/account/uploadAvatar', data, 'loadingDiv', 'multipart/form-data');
}

// 修改邮箱
export function userUpdate_email(data) {
  return $http.post('/api/user/update_email', data, 'loadingDiv');
}

// 修改用户名称
export function userUpdate_user_name(data) {
  return $http.post('/api/user/update_user_name', data, 'loadingDiv');
}

// 修改手机号-发送当前本机验证码
export function updateoldmobilesendsms(data) {
  return $http.post('/api/accountmanage/updateoldmobilesendsms', data, 'loadingDiv');
}

// 修改密码
export function update_password(data) {
  return $http.post('/api/user/update_password', data, 'loadingDiv');
}

// 修改手机号
export function newupdatemobile(data) {
  return $http.post('/api/accountmanage/newupdatemobile', data, 'loadingDiv');
}
